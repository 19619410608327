<template>
  <el-dialog :title=" '数据录入'" :close-on-click-modal="false" :visible.sync="visible">

    <div class="">
        <!-- 测试 -->
        <el-form label-width="80px">

            <el-button type="primary" icon="el-icon-plus" @click="handleAddDetails" class="addButton">添加</el-button>

            <el-table border
                :data="formMessage"
                :row-class-name="rowClassName"
                ref="tb"
            >
                <el-table-column label="序号" align="center" prop="xh" width="50"></el-table-column>
                <el-table-column
                    v-for="(message) in inputDataForm"
                    :label="message.manualEntryFieldName"
                    :key="message.key"
                    :prop="message.id"
                >
                    <template slot-scope="scope">
                        <el-input
                            v-if="message.manualEntryFieldType===1"
                            v-model="scope.row[message.id]"
                        >
                        </el-input>
                        <el-upload
                        v-else-if="message.manualEntryFieldType===2"
                            class="upload-demo"
                            action="/bigdata/addDataAccessTask/fileUpload"
                            accept=".jpg,.png,.jpeg"
                            :before-remove="beforeRemove"
                            :before-upload="beforeUpload"
                            :on-success="upFileSuccess"
                            :limit="1"
                            :file-list="fileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传jpg/jpeg/png文件，限1个</div>
                        </el-upload>
                        <el-upload
                        v-else-if="message.manualEntryFieldType===3"
                            class="upload-demo"
                            action="/bigdata/addDataAccessTask/fileUpload"
                            accept=".mp4,.mov,.avi,.wmv"
                            :before-remove="beforeRemove"
                            :before-upload="beforeUpload"
                            :on-success="upFileSuccess"
                            :limit="1"
                            :file-list="fileList">
                            <el-button size="small" type="primary">点击上传</el-button>
                            <div slot="tip" class="el-upload__tip">只能上传mp4/mov/avi/wmv文件，限1个</div>
                        </el-upload>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                    <el-button type="text" @click="removeShowList(scope.$index,scope.row)" class="warningButton">删除</el-button>
                    </template>
                </el-table-column>

            </el-table>
            <el-button type="primary" @click="inputDataToDataBase()">录入数据</el-button>
            <!-- <el-button type="primary" @click="showTestMessage()">查看数据</el-button> -->
        </el-form>

  </div>
  </el-dialog>
</template>

<script>


export default {
  props:{
      manualEntryId:{
          type:Number,
          required:true
      }
  },
  name:'Simple',
  components:{

  },
  data() {
    return {
      newManualEntryId:0,
      visible: false,
      code:'',
      fileList: [],
      dataForm:{
          tableName:'',
          fields:[
              {manualEntryFieldName:'',manualEntryFieldType:''}
          ]
      },
      fieldTypes:[
      {value:'1',label:'文本'},
        {value:'2',label:'图片'},
        {value:'e',label:'视频'}
      ],
      inputDataForm:[

      ],
      formMessage:[

      ],
      checkedDetail:[

      ]
    };
  },
  watch:{
      manualEntryId(newValue,oldValue){
        this.newManualEntryId=newValue
      }
  },
  created(){
      this.newManualEntryId=this.manualEntryId;

  },
  activated(){

  },
  mounted(){

  },

  methods: {
    init() {
      console.log(this.newManualEntryId);
      this.inputDataForm=[];
      this.formMessage=[];
      this.getData();

    },
    getData(){
        this.getRequest(
            "/manualEntry/getFieldInfo/?manualEntryId=" +
            this.newManualEntryId
        ).then((resp) => {
            if (resp) {
            this.inputDataForm = resp.data;
            this.visible=true;
            }
        });
    },
    //新增字段
    addField(){
        this.dataForm.fields.push({
            manualEntryFieldName:'',
        });
    },
    //删除字段
    removeField(item){
        var index = this.dataForm.fields.indexOf(item);
        if(index!=-1){
            this.dataForm.fields.splice(index,1);
        }
    },

    rowClassName({ row, rowIndex }) {

        row.xh = rowIndex + 1;
    },


    //录入信息时新增一条
    handleAddDetails() {
      if (this.formMessage == undefined) {
        this.formMessage = new Array();
      }
      let obj = {};
      for(var i=0;i<this.inputDataForm.length;i++){
          this.$set(obj,this.inputDataForm[i].id,"");
      }
      this.formMessage.push(obj);
    },

    //删除
    removeShowList(index,row){
        this.formMessage.splice(index,1);
    },
    //查看输入的数据
    showTestMessage(){
        var complete=true;
        var inputData=[];
        for(var i=0;i<this.formMessage.length;i++){
            var obj={};
            obj=JSON.parse(JSON.stringify(this.formMessage[i]));
            inputData.push(obj);
        }
        for(var i=0;i<inputData.length;i++){
            this.$delete(inputData[i],'undefined');
            this.$delete(inputData[i],"xh")
        }
        for(var i=0;i<inputData.length;i++){
            var fieldArr=Object.keys(inputData[i]);
            for(var j=0;j<fieldArr.length;j++){
                if(inputData[i][fieldArr[j]].length==0){
                    complete=false;
                }
            }
        }
        console.log(inputData);
        console.log(this.newManualEntryId);
        if(!complete){
            this.$message({
                message:'请补全信息',
                type:'error'
            });
        }
    },
    inputDataToDataBase(){
        console.log(this.fileList);
        console.log(this.formMessage);
        if(this.formMessage.length == 0){
            this.$message({
                message:'未插入数据',
                type:'error'
            });
        }
        else
        {
            var complete=true;
            var inputData=[];
            for(var i=0;i<this.formMessage.length;i++){
                
                var obj={};
                obj=JSON.parse(JSON.stringify(this.formMessage[i]));
                var json = {}
                for (var o in obj) {
                    if(obj[o].length != 0)
                    {
                        json[o.toString()] = obj[o];
                    }
                }
                console.log(obj)
			    let obj1 = {"manualEntryId" : this.newManualEntryId};
			    let twoobj = Object.assign(obj1,json);
                
                inputData.push(twoobj);
                console.log(inputData);
            }
            for(var i=0;i<inputData.length;i++){
                this.$delete(inputData[i],'undefined');
                this.$delete(inputData[i],"xh")
            }
            for(var i=0;i<inputData.length;i++){
                var fieldArr=Object.keys(inputData[i]);

                for(var j=0;j<fieldArr.length;j++){
                    if(inputData[i][fieldArr[j]].length==0){
                        complete=false;
                    }
                }
            }
            if(!complete){
                this.$message({
                    message:'请补全信息',
                    type:'error'
                });
            }else{
                let data={
                    data:inputData,
                }
                this.postRequest("/manualEntry/inputData",data)
                    .then((resp)=> {
                      console.log(resp)
                    if (resp && resp.data.code === 0) {
                      this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500,
                        onClose: () => {
                          this.visible = false
                          this.$emit('refreshDataList')
                        }
                      })
                    }
                })
            }
        }

    },
    handleRemove(file, fileList) {
        console.log(file, fileList);
      },
    handlePreview(file) {
        console.log(file);
    },
    handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
        return this.$confirm(`确定移除 ${ file.name }？`);
    },
      // 文件改变时
    /* fileChange(file, fileList) {
        this.fileList=fileList;
        this.fileList.append(file);
    }, */
  },
};
</script>
<style scoped>
.el-input {
    margin-right: 10px;
}
.el-select {
    width: 250px;
    margin-right: 10px;
}
.warningButton{
  color: #F56C6C;
}
.addButton{
    margin-bottom: 15px;
}
</style>
